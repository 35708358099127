<template>
  <svg :height="`${height}px`" :width="`${width}px`" :class="`fill-current text-${color}`" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 10.5742C12.9946 10.5742 13.9484 10.1791 14.6517 9.47587C15.3549 8.77261 15.75 7.81878 15.75 6.82422C15.75 5.82966 15.3549 4.87583 14.6517 4.17257C13.9484 3.46931 12.9946 3.07422 12 3.07422C11.0054 3.07422 10.0516 3.46931 9.34835 4.17257C8.64509 4.87583 8.25 5.82966 8.25 6.82422C8.25 7.81878 8.64509 8.77261 9.34835 9.47587C10.0516 10.1791 11.0054 10.5742 12 10.5742Z"
      fill="white"
    />
    <path
      d="M3 0C2.20435 0 1.44129 0.31607 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3L0 15C0 15.7956 0.316071 16.5587 0.87868 17.1213C1.44129 17.6839 2.20435 18 3 18H21C21.7956 18 22.5587 17.6839 23.1213 17.1213C23.6839 16.5587 24 15.7956 24 15V3C24 2.20435 23.6839 1.44129 23.1213 0.87868C22.5587 0.31607 21.7956 0 21 0H3ZM19.197 16.5C18.5175 14.595 16.557 12 12 12C7.4445 12 5.4825 14.595 4.803 16.5H3C2.60218 16.5 2.22064 16.342 1.93934 16.0607C1.65804 15.7794 1.5 15.3978 1.5 15V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H21C21.3978 1.5 21.7794 1.65804 22.0607 1.93934C22.342 2.22064 22.5 2.60218 22.5 3V15C22.5 15.3978 22.342 15.7794 22.0607 16.0607C21.7794 16.342 21.3978 16.5 21 16.5H19.197Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '#3b82f6'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '18'
    }
  }
}
</script>
